const colors = {
  primary: "#FFFFFF",
  primaryHover: "#4fc126",
  secondary: "#E1E1E1",
  secondaryHover: "purple",
  dark: "#3B3B3B",
  highlight: "#FF2A42",
  white: "white",
  black: "black",
  backgroundOutside: "#FAFAFA",
};

export default colors;
