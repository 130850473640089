import React from "react";
import {
  FooterContent,
  FooterCol,
  ConverFooter,
  BlockList,
  ListItems,
  DownloadImages,
  ConverDownload,
  CoverFooterBot,
  Privacy,
  ConverPolicy,
} from "./Layout.style";
import { paddingBottom } from "styled-system";

const Footer = () => {
  return (
    <FooterContent>
      <FooterCol span={20}>
        <CoverFooterBot>
          <ConverPolicy>
            <a href="/privacy-policy">Privacy Policy </a>|{" "}
            <a href="/Legal"> Terms and Conditions</a>
          </ConverPolicy>
          <div style={{marginBottom:'20px'}}>
            <img src="./Ghost/Chek_Black.png" width={"auto"} height={"56px"} />
            <p>Copyright © {new Date().getFullYear()} Chek</p>
          </div>
          <div style={{ fontSize: "15px", fontWeight: 100, color: "#666"}}>
            <span
              style={{ color: "black", fontSize: "20px", fontWeight: "bold" }}
            >
              SUPPORT
            </span>
            <br />
            <span>ChekID: CHEKSUPPORT</span>
            <br />
            <span>GhostID: CHEKSUPPORT</span>
            
          </div>
          <Privacy>
            <a href="/privacy-policy">Privacy Policy </a>
            <br /> <a href="/Legal"> Terms and Conditions</a>
          </Privacy>
        </CoverFooterBot>
      </FooterCol>
    </FooterContent>
  );
};

export default Footer;
