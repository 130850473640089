import React, { useState } from "react";
import styles from "./styled.module.scss";
import { Link } from "react-router-dom";
import "../Slide.css";
import { Col, Row } from "antd";

const Header = () => {
  const [isActive, setActive] = useState(null);
  const ClickMenu = () => {
    isActive ? setActive(false) : setActive(true);
  };

  const CloseMenu = () => {
    setActive(null);
  };
  return (
    <>
      <Row className={styles.rowContainer}>
        <Col span={20}>
          <div className={styles.container}>
            <div className={styles.blockLogo}>
              <a href="/home">
                <img
                  src="./Ghost/Chek_Black.png"
                  width={"150px"}
                  height={"auto"}
                />
              </a>
            </div>
            <div className={styles.blockItemHeader}>
              {/* Block child */}
              <div className={styles.dropdown}>
                <Link to="/encryption">
                  <div className={styles.blockHeader}>Encryption</div>
                </Link>
              </div>
            </div>
            <div className={styles.blockItemHeader}>
              {/* Block child */}
              <div className={styles.dropdown}>
                <Link to="/chek-os">
                  <div className={styles.blockHeader}>CHEK OS</div>
                </Link>
              </div>
            </div>

            <div className={styles.blockItemHeader}>
              {/* Block child */}
              <div className={styles.dropdown}>
                <a href="https://shop.getchek.com/install" target="_blank">
                  <div className={styles.blockHeader}>FLASH TOOL</div>
                </a>
              </div>
            </div>

            <div
              className={`${styles.blockItemHeader} ${styles.blockItemHeaderRegister}`}
            >
              {/* Block child */}
              <div className={styles.dropdown}>
                <a
                  href="https://shop.getchek.com/partners/create_account"
                  target="_blank"
                >
                  <div className={styles.blockHeader}>Sign In/Register</div>
                </a>
              </div>
            </div>

            <div class="nav">
              <button
                class={
                  isActive == null || !isActive ? "btn-nav" : "btn-nav animated"
                }
                onClick={ClickMenu}
              >
                <span class="icon-bar top"></span>
                <span class="icon-bar middle"></span>
                <span class="icon-bar bottom"></span>
              </button>
            </div>
            <div
              class={
                isActive == null
                  ? "hidden"
                  : !isActive
                  ? "nav-content hideNav"
                  : "showNav nav-content"
              }
            >
              <ul class="nav-list" onClick={() => CloseMenu()}>
                <li class="nav-item">
                  <Link to="/home">
                    <span class="item-anchor">Home</span>
                  </Link>
                </li>
                {/* <li class="nav-item">
              <Link to="/about-company">
                <span class="item-anchor">Company</span>
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/about-career">
                <span class="item-anchor">Career</span>
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/about-contact">
                <span class="item-anchor">Contact</span>
              </Link>
            </li> */}
                <li class="nav-item">
                  <Link to="/encryption">
                    <span class="item-anchor">Encryption</span>
                  </Link>
                </li>
                {/* <li class="nav-item">
              <Link to="/ghost-mess">
                <span class="item-anchor">CHEK STACK</span>
              </Link>
            </li>{' '} */}
                <li class="nav-item">
                  <Link to="/chek-os">
                    <span class="item-anchor">CHEK OS</span>
                  </Link>
                </li>{" "}
                <li class="nav-item">
                  <a href="https://shop.getchek.com/install" target="_blank">
                    <span class="item-anchor">FLASH TOOL</span>
                  </a>
                </li>
                {/* <li class="nav-item">
              <Link to="/buyer">
                <span class="item-anchor">Product</span>
              </Link>
            </li>*/}
                <li class="nav-item">
                  <a
                    href="https://shop.getchek.com/partners/create_account"
                    target="_blank"
                  >
                    <span class="item-anchor">Sign In/Register</span>
                  </a>
                </li>
              </ul>
              <div class="line-betwen"></div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Header;
