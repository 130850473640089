import styled, { keyframes } from 'styled-components';
import { Col, Row } from 'antd';

const FooterContent = styled(Row)`
  height: 200px;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    height: 300px;
  }
  @media (max-width: 800px) {
    height: 200px;
  }
  @media (max-width: 500px) {
    height: 150px;
  }
`;
const FooterCol = styled(Col)``;
const ConverFooter = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;
const BlockList = styled.ul`
  width: 30%;
  list-style: none;
  justify-content: space-between;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const ListItems = styled.li`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
  }
`;
const ConverPolicy = styled.li`
  display: none;
  @media (max-width: 800px) {
    display: block;
    a {
      font-weight: 500;
      text-transform: uppercase;
      font-size: 1rem;
      color: #393f4d;
    }
  }
  @media (max-width: 600px) {
    margin-bottom: 20px
  }
`;
const DownloadImages = styled.a`
  margin-top: 20px;
  width: 185px;
  /* height: 91px; */
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
  cursor: pointer;
  transition: all 0.7s ease;
  &:hover {
    cursor: pointer;
    transform: scale(1.08);
  }
`;
const ConverDownload = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
const CoverFooterBot = styled.div`
  display: flex;
  justify-content: space-between;
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 30px;
  margin-top: 30px;
  color: #000000;
  p {
    margin: 0;
    color: #000000;
  }
  @media (max-width: 500px) {
    margin-bottom: 30px;
    flex-direction: column;
    text-align: center;
  }
`;
const Privacy = styled.div`
  a {
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins-Regular;
    color: #666;
    text-transform: uppercase;
    font-size: 1rem;
    color: #393f4d;
    :hover {
      color: #39adaa;
    }
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
export {
  FooterContent,
  FooterCol,
  ConverFooter,
  BlockList,
  ListItems,
  DownloadImages,
  ConverDownload,
  CoverFooterBot,
  Privacy,
  ConverPolicy,
};
